body {
  font-family:  sans-serif;
}

/*.App{*/
/*  background-color: #272c34;*/
/*}*/

.content{
  padding: 10px;
  margin-bottom: 15px;
}

.sidenav {
  height: 100%;
  width: 230px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav .nav-items a {
   padding: 6px 8px 6px 16px;
   text-decoration: none;
   font-size: 20px;
   /*color: #818181;*/
   color: #fff;
   display: block;
 }

.sidenav .social-icons {
  padding: 100px 8px 6px 16px;
  text-align: center;
}

.sidenav .social-icons a {
  padding: 0px;
}



@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.bottom {
  padding-top: 100px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 700px) {
  .sidenav {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0px !important;
  }
  .sidenav a {float: left;}
  div.content {margin-left: 0;}
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
